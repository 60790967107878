<template>
  <v-card>
    <v-system-bar window>
      Lektion {{ isNew ? "hinzufügen" : "ändern" }} {{ schoolClass.code }}
    </v-system-bar>
    <v-list>
      <v-list-item v-if="isNew">
        <CourseInput
          v-model="course"
          :schoolClass="schoolClass.id"
          :startDate="date"
          :endDate="date"
          label="Kurs"
        ></CourseInput>
      </v-list-item>
      <Course v-else :value="lesson.course"></Course>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <DateValue :value="date" full></DateValue>
          </v-list-item-title>

          <v-list-item-subtitle v-if="!isNew">
            {{ lesson.startTime }} – {{ lesson.endTime }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isNew">
        <LookupValueInput
          v-model="site"
          label="Standort"
          resource="common/site"
        ></LookupValueInput>
      </v-list-item>
      <v-list-item v-if="isNew">
        <PeriodInput
          :disabled="!site"
          v-model="period"
          :site="site"
          label="Lektion"
        ></PeriodInput>
      </v-list-item>

      <LookupValueBtnToggle
        v-if="!isNew"
        v-model="status"
        resource="register/lessonstatus"
      ></LookupValueBtnToggle>
      <v-list-item>
        <RoomInputMulti
          v-model="rooms"
          :disabled="!isNew && (!status || status.code != 'roomChange')"
        ></RoomInputMulti>
      </v-list-item>
      <v-list-item>
        <v-text-field
          label="Kommentar"
          v-model="comments"
          :disabled="!status || status.code != 'commented'"
        ></v-text-field>
      </v-list-item>
    </v-list>
    <v-card-text>
      <pre>{{ item }}</pre>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text @click="$emit('close')"> abbrechen </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="danger"
        text
        @click="undoLessonChange()"
        :loading="undoing"
        v-if="lesson && lesson.status.code != 'asPlanned'"
      >
        rückgängig
      </v-btn>
      <v-btn
        :color="isNew ? 'success' : 'primary'"
        text
        @click="save()"
        :loading="saving"
      >
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Course from "@/components/Course.vue";
import CourseInput from "common/components/CourseInput.vue";
import DateValue from "common/components/DateValue.vue";
import LookupValueBtnToggle from "common/components/LookupValueBtnToggle.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PeriodInput from "common/components/PeriodInput.vue";
import RoomInputMulti from "@/components/RoomInputMulti.vue";

export default {
  name: "LessonChangesForm",
  components: {
    Course,
    CourseInput,
    DateValue,
    LookupValueBtnToggle,
    LookupValueInput,
    PeriodInput,
    RoomInputMulti,
  },
  props: ["date", "lesson", "schoolClass"],
  data: () => ({
    comments: "",
    course: null,
    period: null,
    rooms: [],
    saving: false,
    site: null,
    status: null,
    undoing: false,
  }),
  computed: {
    isNew() {
      return this.lesson.id == 0;
    },
    item() {
      if (this.isNew) {
        return {
          date: this.date,
          period: { id: this.period ? this.period.id : 0 },
          course: { id: this.course ? this.course.id : 0 },
          rooms: this.rooms.map((el) => {
            return { id: el.id };
          }),
          comments: this.comments,
        };
      }
      return {
        id: this.lesson.lessonId,
        status: { id: this.status ? this.status.id : 0 },
        rooms: this.rooms.map((el) => {
          return { id: el.id };
        }),
        comments: this.comments,
      };
    },
  },
  watch: {
    lesson() {
      this.prepare();
    },
  },
  methods: {
    async undoLessonChange() {
      this.undoing = true;
      await this.apiDelete({
        resource: "register/lesson",
        id: this.lesson.lessonId,
      });
      this.$emit("update");
      this.$emit("close");
      this.undoing = false;
    },
    async save() {
      this.saving = true;
      await this.apiSave({
        resource: "register/lesson",
        create: this.isNew,
        data: this.item,
      });
      this.$emit("update");
      this.$emit("close");
      this.saving = false;
    },
    prepare() {
      this.rooms = this.lesson.rooms;
      this.status = this.lesson.status;
      this.comments = this.lesson.statusText;
    },
  },
  created() {
    this.prepare();
  },
};
</script>
