<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="selectedRooms"
    ref="roomInput"
    autofocus
    auto-select-first
    menu-props="closeOnContentClick"
    :items="rooms"
    chips
    label="Zimmer"
    item-text="description"
    item-value="id"
    single-line
    hide-details
    return-object
    multiple
    :search-input.sync="roomSearchInput"
  >
    <template v-slot:selection="data">
      <v-chip
        v-if="data.index < maxShown"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        :disabled="$attrs.disabled"
        @click:close="data.parent.selectItem(data.item)"
      >
        {{ data.item.code }}
      </v-chip>
      <v-chip small v-if="data.index == maxShown">
        +{{ selectedRooms.length - maxShown }} weitere
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.code }} </v-list-item-title>
        <v-list-item-subtitle
          >{{ data.item.description.replace(data.item.code + " - ", "") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    query: { type: Boolean },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: true,
      rooms: [],
      roomDialog: false,
      roomSearchInput: null,
      roomTypes: [],
      selectedRooms: [],
      tab: 1,
      maxShown: 5,
    };
  },
  watch: {
    value() {
      this.selectedRooms = this.value;
    },
    selectedRooms() {
      if (this.query) {
        const roomCodes = this.selectedRooms.map((el) => el.code);
        window.localStorage.setItem(
          "roomReservationRooms",
          JSON.stringify(roomCodes)
        );
        this.$router
          .replace({
            query: { rooms: roomCodes },
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      }

      this.select();
    },
  },
  methods: {
    addRooms(rooms) {
      for (const room of rooms) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          this.selectedRooms.push(room);
        }
      }
    },
    setRooms(rooms) {
      this.selectedRooms = [];
      this.addRooms(rooms);
      this.roomDialog = false;
    },
    removeRooms(rooms) {
      for (const room of rooms) {
        const index = this.selectedRooms.findIndex((el) => el.id == room.id);
        if (index >= 0) {
          this.selectedRooms.splice(index, 1);
        }
      }
    },
    addType(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          this.selectedRooms.push(room);
        }
      }
    },
    setType(typeId) {
      this.selectedRooms = [];
      this.addType(typeId);
      this.roomDialog = false;
    },
    allAdded(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          return false;
        }
      }
      return true;
    },

    someMissing(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        if (this.selectedRooms.some((el) => el.id == room.id)) {
          return false;
        }
      }
      return true;
    },
    removeType(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        const index = this.selectedRooms.findIndex((el) => el.id == room.id);
        if (index >= 0) {
          this.selectedRooms.splice(index, 1);
        }
      }
    },
    getRoomsByType(typeId) {
      return this.rooms.filter((el) => el.type.id == typeId);
    },
    async fetchData() {
      this.loading = true;
      this.rooms = await this.apiList({
        resource: "common/room",
      });
      this.roomTypes = await this.apiList({
        resource: "common/roomtype",
      });

      this.loading = false;
    },
    select() {
      this.$emit("input", this.selectedRooms);
      this.roomSearchInput = null;
      window.setTimeout(() => this.$refs.roomInput.$refs.input.focus());
    },
  },
  async created() {
    await this.fetchData();
    for (const room of this.value) {
      this.selectedRooms.push(room);
    }
  },
};
</script>
